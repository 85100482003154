<template>
  <div class="brandList">
    <nav-bar title="配件列表"></nav-bar>
    <div class="search">
      <van-search
        v-model="keyWord"
        placeholder="请输入配件名称、品类、品牌、OE、规格型号、出厂编码"
        shape
        :clearable="false"
      >
        <template #left-icon>
          <div style="visibility: hidden; width: 0"></div>
        </template>
        <template #right-icon>
          <div class="icon" @click="search"><van-icon name="search" /></div>
        </template>
      </van-search>
    </div>
    <div class="select flex">
      <div class="selectItem" @click="categoryOpen">
        <!-- {{ category.name || "品类" }} <van-icon name="arrow-down" /> -->
        品类 <img src="@/assets/triangle.png" alt="" style="width: 14px" />
      </div>
      <div class="selectItem" @click="brandOpen" v-if="fromName != 'queryOrigin'">
        <!-- {{ brand || "品牌" }} <van-icon name="arrow-down" /> -->
        品牌 <img src="@/assets/triangle.png" alt="" style="width: 14px" />
      </div>
      <!-- <div class="selectItem" @click="$router.push('choiceCarModel')"> -->
      <div class="selectItem" @click="openModel">
        <!-- {{ model || "车型" }} <van-icon name="arrow-down" /> -->
        车型 <img src="@/assets/triangle.png" alt="" style="width: 14px" />
      </div>
    </div>
    <div class="tags" v-show="productId || brandId || model">
      <van-tag
        v-for="(item, index) in [productName, brandName, modelName]"
        :key="index"
        v-show="item"
        plain
        type="primary"
        closeable
        size="large"
        @close="clostTag(index)"
        round
        color="#E6E6E6"
        text-color="#ADADAD"
        >{{ item }}</van-tag
      >
      <van-tag
        v-show="productId || brandId || model"
        plain
        type="primary"
        size="large"
        round
        color="#E6E6E6"
        @click="clostReset"
        text-color="#ADADAD"
        >重置</van-tag
      >
    </div>
    <div class="notice" v-show="productId || brandId || model">
      已为您查询到
      <span style="color: #464646">{{ totalCount }}件</span> 匹配的商品
    </div>
    <div class="commenWrapper">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        v-show="allList.length > 0"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="commonWrapper">
            <div v-for="(item, index) in allList" :key="index">
              <div class="title">
                {{ item.productName }}
              </div>
              <div
                class="list"
                v-for="(val, i) in item.goodsList"
                :key="i"
                @click="toPartDetail(val)"
              >
                <div class="contentTitle van-hairline--bottom">
                  {{ val.goodsName }}
                </div>
                <div class="flex">
                  <div>OE号：</div>
                  <div>{{ val.oe }}</div>
                </div>
                <div class="flex">
                  <div>4S店参考价：</div>
                  <div style="color: #fe703f">
                    <span v-show="val.price">￥{{ val.price }}</span>
                  </div>
                </div>
                <div class="flex">
                  <div>规格型号：</div>
                  <div>{{ val.goodsStyle }}</div>
                </div>
                <div class="flex">
                  <div>出厂编码：</div>
                  <div>{{ val.serialNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div v-show="allList.length <= 0" class="empty">
        <img :src="emptyImg" alt="" />
        <div>未查询到可用配件</div>
      </div>
    </div>
    <!-- 品类popup开始 -->
    <van-popup
      v-model="categoryShow"
      position="right"
      :style="{ height: '100%', width: '80%', overflow: 'hidden' }"
    >
      <div class="cpopup">
        <van-icon
          name="cross"
          class="close"
          @click="closePopup('categoryShow')"
        />
        <van-tabs v-model="categoryActive">
          <van-tab title="易损件">
            <van-collapse v-model="categoryActiveNamesYsj">
              <van-collapse-item
                :title="item.firstProductName"
                :name="index"
                v-for="(item, index) in ysjList"
                :key="index"
              >
                <van-cell
                  v-for="(val, key) in item.productList"
                  :key="key"
                  :title="val.productName"
                  center
                  @click="categoryClick(val, 1)"
                >
                  <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                  <template #right-icon>
                    <van-icon
                      name="success"
                      color="red"
                      v-show="productId == val.productId"
                    />
                  </template>
                </van-cell>
              </van-collapse-item>
            </van-collapse>
          </van-tab>
          <van-tab title="车型件"> </van-tab>
          <van-collapse v-model="categoryActiveNamesCxj">
            <van-collapse-item
              :title="item.firstProductName"
              :name="index"
              v-for="(item, index) in cxjList"
              :key="index"
            >
              <van-cell
                v-for="(val, key) in item.productList"
                :key="key"
                :title="val.productName"
                center
                @click="categoryClick(val, 2)"
              >
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                  <van-icon
                    name="success"
                    color="red"
                    v-show="productId == val.productId"
                  />
                </template>
              </van-cell>
            </van-collapse-item>
          </van-collapse>
        </van-tabs>
      </div>
    </van-popup>
    <!-- 品类popup结束 -->
    <!-- 品牌popup开始 -->
    <van-popup
      v-model="brandShow"
      position="right"
      :style="{ height: '100%', width: '80%', 'overflow-x': 'hidden' }"
    >
      <div class="cpopup">
        <van-icon name="cross" class="close" @click="closePopup('brandShow')" />
        <div class="brand">品牌</div>
        <van-index-bar
        style="width:100%"
          ref="scrollBar"
          :index-list="indexList"
          @change="indexBarChange"
        >
          <span v-for="(item, index) in arrayAllBrand" :key="index">
            <van-index-anchor
              :index="indexList[index]"
              style="z-index: 99999999;padding: 0 15px;width:90%"
              >{{ indexList[index] }}</van-index-anchor
            >
            <van-cell
            style="width:90%;padding-left:30px"
            
              v-for="(item, index) in item[indexList[index]]"
              :key="index"
              :title="item.brandName"
              center
              @click="brandClick(item)"
            >
              <!-- 使用 right-icon 插槽来自定义右侧图标 -->
              <template #right-icon>
                <van-icon
                  name="success"
                  color="red"
                  v-show="brandId == item.brandId"
                />
              </template>
            </van-cell>
          </span>
        </van-index-bar>
      </div>
    </van-popup>
    <div class="rightChar" v-show="brandShow">
      <div
        v-for="(item, index) in indexList"
        :key="index"
        :class="(item == indexChar && 'active') || ''"
        @click="scrollIndexBar(item)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 品牌popup结束 -->
    <!-- 车型popup开始 -->
    <van-popup
      v-model="modelShow"
      position="right"
      :style="{ height: '100%', width: '90%', 'overflow-x': 'hidden' }"
    >
      <model
        @changeModel="changeModel"
        @changeLetter="changeLetter"
        @rightChar="rightChar"
        ref="model"
      ></model>
    </van-popup>

    <div class="rightChar" v-show="modelShow && charCurrent == 1" style="top: 40%">
      <div
        v-for="(item, index) in childLetter"
        :key="index"
        :class="(item == childIndexChar && 'active') || ''"
        @click="childScrollIndexBar(item)"
      >
        {{ item }}
      </div>
    </div>

    <!-- 车型popup结束 -->
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import model from "./ChoiceCarModel.vue";
import emptyImg from "@/assets/empty.png";
import { getBrandGoodsList, getAllProductList, getAllBrandList } from "@/api";
import {
  Search,
  Icon,
  Popup,
  Tab,
  Tabs,
  Collapse,
  CollapseItem,
  Cell,
  Tag,
  IndexBar,
  IndexAnchor,
  Grid,
  GridItem,
  Dialog,
  List,
  PullRefresh,
} from "vant";
export default {
  name: "brandList",
  components: {
    NavBar,
    model,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [Tag.name]: Tag,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Dialog.name]: Dialog,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      fromName: '',
      emptyImg,
      brandName: "", //品牌列表传过来的
      keyWord: "", //关键词
      //品类相关
      category: {}, //品类的值
      categoryShow: false, //popup
      productType: "",
      productId: "",
      productName: "",
      ysjList: [], //易损件
      cxjList: [], //车型件
      categoryActive: 0,
      categoryActiveNames: [],
      categoryActiveNamesYsj: [],
      categoryActiveNamesCxj: [],
      //品牌相关
      brandList: [], //品牌列表
      brandShow: false, //popup
      //车型相关
      model: "", //车型的值
      modelName: "", //车型名字
      modelShow: false, //popup
      indexList: [],
      brandId: "", //品牌ID
      userId: "",

      allList: [], //列表的值
      totalNum: 0, //商品分类数量
      timer: "", //点击查询的定时器
      loading: false,
      finished: false,
      refreshing: false,
      totalCount: 0,
      current: 1,
      size: 20,

      arrayAllBrand: [],
      firstcharList: [],
      indexChar: "", //用于高亮

      childLetter: [], //子页面letter
      childIndexChar: "", //子页面用于高亮

      charCurrent: 0,
    };
  },
  methods: {
    rightChar(current) {
      console.log(current)
      this.charCurrent = current;
    },
    scrollIndexBar(key) {
      console.log(this.$refs["scrollBar"], key);
      setTimeout(() => {
        this.$refs.scrollBar.scrollTo(key);
      }, 0);
    },
    indexBarChange(key) {
      console.log(key);
      this.indexChar = key;
      // var index = this.indexList.indexOf(key);
      // var length = this.indexList.length;
      // if (index / length > 0.1) {
      //   console.log(50 + (index / length) * 100 + (5 * index) / length + "%");
      //   document.getElementsByClassName("van-index-bar__sidebar")[0].style.top =
      //     50 + (index / length) * 100 + (50 * index) / length + "%";
      //   // document.getElementsByClassName('van-index-bar__sidebar')[0].style.top = '100%'
      // } else {
      //   document.getElementsByClassName("van-index-bar__sidebar")[0].style.top =
      //     "50%";
      // }
    },
    //品类查询打开弹框
    categoryOpen() {
      console.log(this.brandId,!this.brandId)
      if (!this.brandId) {
        Dialog.alert({
          message: "请先选择品牌",
        });
        return;
      }
      var { brandId, userId } = this;
      getAllProductList({ brandId, userId }).then((res) => {
        var { ysjList, cxjList } = res.data.data;
        this.ysjList = ysjList || [];
        this.cxjList = cxjList || [];
        this.categoryShow = true;
      });
    },
    //品牌查询打开弹框
    brandOpen() {
      var { userId } = this;
      getAllBrandList({ userId }).then((res) => {
        console.log(res);
        var { arrayAllBrand, firstcharList } = res.data.data;
        this.arrayAllBrand = arrayAllBrand;
        this.indexList = firstcharList.map((val) => {
          return val.firstchar;
        });
        this.indexChar = this.indexList[0];
        this.brandShow = true;
      });
    },
    //供应商
    onLoad() {
      var data = {
        keyWord: this.keyWord,
        brandId: this.brandId,
        productId: this.productId,
        productType: this.productType,
        carModelId: this.model,
        pageNo: 1,
        pageSize: this.size * this.current,
        userId: this.userId,
      };
      getBrandGoodsList(data)
        .then((res) => {
          console.log(res.data.data);
          if (res && res.data.status.code == 0) {
            var allList = (res.data.data && res.data.data.allList) || [];
            var totalCount = (res.data.data && res.data.data.totalCount) || 0;
            if (allList.length == 0) {
              this.finished = true;
            }
            this.allList = allList || [];
            this.totalCount = totalCount;
            this.loading = false;
            this.current += 1;
            this.finished = false;
            this.refreshing = false;
            console.log(totalCount,this.size * this.current)
            if (totalCount <= this.size * this.current) {
              this.finished = true;
            }
          } else {
            this.finished = true;
            this.loading = false;
          }
        })
        .catch((err) => {
          this.finished = true;
          this.refreshing = false;
          this.loading = false;
        });
    },
    //下拉刷新
    onRefresh() {
      this.allList = [];
      this.finished = true;
      this.loading = true;
      this.current = 1;
      this.onLoad();
    },
    //跳转配件详情
    toPartDetail(item) {
      console.log(item);
      this.$router.push({
        name: "partDetail",
        params: { goodsId: item.goodsId },
      });
    },
    //点击查询
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.current = 1;
        this.onLoad();
      }, 500);
    },
    //打开车型
    openModel() {
      this.modelShow = true;
      setTimeout(() => {
        var type = ''
        if(this.fromName == 'queryOrigin'){
          type = 1;
        }
        this.$refs["model"].getCarBrandList(type);
        console.log(this.$refs["model"]);
      }, 0);
    },
    //子页面调关闭
    changeModel(bol, item) {
      console.log(item, 324);
      if (item) {
        this.modelName = item.carModelName;
        this.model = item.carModelId;
        this.brandId = item.brandId;
        this.brandName = item.brandName;
        this.current = 1;
        this.onLoad();
      }
      this.modelShow = bol;
    },
    //改变子页面scroll
    childScrollIndexBar(key) {
      this.$refs["model"].scrollBar(key);
    },
    //子页面数据回传
    changeLetter(item, key) {
      this.childLetter = item;
      if (key) {
        this.childIndexChar = key;
      }
      console.log(key);
    },
    //关闭标签
    clostTag(index) {
      switch (index) {
        case 0:
          this.productId = "";
          this.productName = "";
          break;
        case 1:
          this.brandId = "";
          this.brandName = "";
          break;
        case 2:
          this.model = "";
          this.modelName = "";
          break;

        default:
          break;
      }
      this.PullRefresh();
    },
    clostReset() {
      this.productName = "";
      this.brandName = "";
      this.modelName = "";
      this.productId = "";
      this.brandId = "";
      this.model = "";
      this.current = 1;
      this.onLoad();
    },
    //点击弹出选品类
    popupShow() {
      this.categoryShow = true;
    },
    //关闭popup
    closePopup(item) {
      if (item == "categoryShow") this.categoryShow = false;
      if (item == "brandShow") this.brandShow = false;
      if (item == "modelShow") this.modelShow = false;
    },
    //选中品类
    categoryClick(item, productType) {
      console.log(item, productType);
      this.productType = productType;
      this.productId = item.productId;
      this.productName = item.productName;
      this.category = item;
      this.current = 1;
      this.onLoad();
      this.categoryShow = false;
    },
    //选中品牌
    brandClick(item) {
      console.log(item);
      this.brandId = item.brandId;
      this.brandName = item.brandName;
      this.brandShow = false;
      this.current = 1;
      this.onLoad();
    },
    setData(from) {
      if(from == "queryOrigin"){
        this.fromName = 'queryOrigin'
      }
      var { brandId, brandName } = this.$route.params;
      this.brandId = brandId;
      this.brandName = brandName;
      this.userId = sessionStorage.getItem("userId");
      this.current = 1;
      this.model = "";
      this.modelName = "";
      this.productId = "";
      this.productName = "";
      this.keyWord = "";
      this.onLoad();
    },
  },
  deactivated() {
    this.current = 1;
    window.onbeforeunload = null;
  },
  mounted() {
    window.onbeforeunload = function (e) {
      e = e || window.event;

      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = "刷新页面会丢失当前已查询的数据";
      }

      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "刷新页面会丢失当前已查询的数据";
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "queryBrand" || from.name == "queryOrigin") {
      next((vm) => {
        vm.setData(from.name);
      });
    } else {
      next((vm) => {
        vm.userId = sessionStorage.getItem("userId");
        vm.onLoad();
      });
    }
  },
};
</script>

<style lang="less" scoped>
.brandList {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  .rightChar {
    position: fixed;
    right: 10px;
    top: 15%;
    z-index: 9999;
    font-size: 12px;
    .active {
      color: green;
    }
  }
  /deep/.van-index-anchor{
    font-weight: bold;
    font-size: 16px;
    color: green;
    border-top: 0.5px solid #ebedf0;
    border-bottom: 1px solid #ebedf0;
  }
  /deep/.van-index-bar__sidebar {
    display: none !important;
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80px;
      margin: 60px 0 30px;
    }
  }
  background: #f8f8f8;
  padding-top: 50px;
  /deep/.van-tabs__line {
    background-color: #1677ff !important;
  }
  .cpopup {
    width: 100%;
    position: relative;
    .close {
      position: absolute;
      left: 15px;
      top: 14px;
      z-index: 2;
      font-size: 20px;
    }
    .brand {
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .commenWrapper {
    // height: 800px;
    padding: 10px 15px;
    .title {
      font-size: 15px;
      height: 53px;
      line-height: 53px;
      font-weight: bold;
      color: #161616;
    }
    .list {
      background: #fff;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 0 15px 0;
      .contentTitle {
        font-size: 14px;
        font-weight: bold;
        color: #161616;
        height: 40px;
        line-height: 40px;
      }
      .flex {
        margin: 10px 0;
        justify-content: space-between;
        div {
          &:first-child {
            color: #767676;
            width: 95px;
          }
          &:last-child {
            flex: 1;
            word-break: break-all;
            color: #464646;
            text-align: right;
          }
        }
      }
    }
  }
  .tags {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .van-tag {
      margin: 0 5px 10px 0;
    }
  }
  .notice {
    background: #fff;
    height: 35px;
    line-height: 35px;
    color: #767676;
    text-indent: 15px;
  }
  .select {
    background: #fff;
    margin-top: 10px;
    .selectItem {
      display: flex;
      align-items: center;
      justify-content: center;
      .van-icon {
        margin-left: 5px;
      }
    }
    & > div {
      flex: 1;
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
  }
  .search {
    .flex {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        height: 20px;
        margin: 0 10px;
        width: 1px;
        background: #ccc;
      }
      .icon {
        width: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>